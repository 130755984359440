.heading{
    font-size: 100px;
    color:white;
}
.tagline{
    font-size:50px;
    color:white;
    margin-top: -60px;
}
.blog-content{
    font-size: 20px;
    color: #a8a4a4;
}
.endline{
    font-style: italic;
}
.endline a{
    cursor: none;
    text-decoration: underline;
    color:#a8a4a4;
}

@media screen and (max-width: 1750px) {
    .heading {
        font-size: 70px;
    }

    .tagline {
        font-size: 40px;
    }

    .blog-content {
        font-size: 18px;
    }
}

@media screen and (max-width: 1140px) {
    .heading {
        font-size: 60px;
    }
    .tagline {
        font-size: 40px;
    }
    .blog-content {
        font-size: 20px;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1178px) {
    .heading {
        font-size: 60px;
    }
    .tagline {
        font-size: 40px;
    }
    .blog-content {
        font-size: 20px;
    }
}

@media screen and (max-width: 878px) {
    .heading {
        font-size: 50px;
    }
    .tagline {
        font-size: 30px;
        margin-top: -35px;
    }
    .blog-content {
        font-size: 20px;
    }
}

@media screen and (max-width: 702px) {
    .heading {
        font-size: 40px;
    }
    .tagline {
        font-size: 30px;
        margin-top: -25px;
    }
    .blog-content {
        font-size: 17px;
    }
}

@media screen and (max-width: 474px) {
    .heading {
        font-size: 40px;
    }
    .tagline {
        font-size: 20px;
        margin-top: -20px;
    }
    .blog-content {
        font-size: 15px;
    }
}