.blog-heading{
    font-size: 70px;
}
.blog-description{
    font-size: 50px;
    margin-top: -60px;
}

@media screen and (max-width: 1750px) {
    .blog-heading {
        font-size: 60px;
    }

    .blog-description {
        font-size: 40px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 1140px) {
    .blog-heading {
        font-size: 60px;
    }
    .blog-description {
        font-size: 50px;
        margin-top: -75px;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1178px) {
    .blog-heading {
        font-size: 60px;
    }
    .blog-description {
        font-size: 50px;
        margin-top: -75px;
    }
}

@media screen and (max-width: 878px) {
    .blog-heading {
        font-size: 50px;
    }
    .blog-description {
        font-size: 40px;
        margin-top: -50px;
    }
}

@media screen and (max-width: 702px) {
    .blog-heading {
        font-size: 40px;
    }
    .blog-description {
        font-size: 30px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 474px) {
    .blog-heading {
        font-size: 40px;
    }
    .blog-description {
        font-size: 25px;
        margin-top: -30px;
    }
}