.blog-titles{
    color:white;
    font-size: 40px;
}
.individual-blog-container{
    max-height: 300px;
    overflow: hidden;
}
.description{
    color:#a8a4a4;
    font-size: 20px;
}

@media screen and (max-width: 1750px) {
    .blog-titles {
        font-size: 30px;
    }
    .description {
        font-size: 20px;
    }
}

@media screen and (max-width: 878px) {
    .blog-titles {
        font-size: 30px;
    }
    .description {
        font-size: 20px;
    }
}

@media screen and (max-width: 702px) {

    .blog-titles {
        font-size: 25px;
    }
    .description {
        font-size: 17px;
    }
}

@media screen and (max-width: 474px) {
    .blog-titles {
        font-size: 20px;
    }
    .description {
        font-size: 15px;
    }
}