@keyframes line-anim {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    from {
        fill: transparent;
    }

    to {
        fill: white;
    }
}

#logo path:nth-child(1) {
    stroke-dasharray: 549.0669555664062;
    stroke-dashoffset: 549.0669555664062;
    animation: line-anim 2s ease forwards;
}

#logo {
    animation: fill 0.4s ease forwards 1.2s;
}
.ant-layout-header{
    background-color: #1f1f1f;
}